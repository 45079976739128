import React from "react";

const ToolSvg = (props) => (
  <span className="anticon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      viewBox="0 0 16 16"
      width="16"
    >
      <g fill="#444">
        <path d="m10.3 8.2-.9.9.9.9-1.2 1.2 4.3 4.3c.6.6 1.5.6 2.1 0s.6-1.5 0-2.1zm3.9 6.8c-.4 0-.8-.3-.8-.8 0-.4.3-.8.8-.8s.8.3.8.8-.3.8-.8.8z" />
        <path d="m3.6 8 .9-.6 1.5-1.7.9.9.9-.9-.1-.1c.2-.5.3-1 .3-1.6 0-2.2-1.8-4-4-4-.6 0-1.1.1-1.6.3l2.9 2.9-2.1 2.1-2.9-2.9c-.2.5-.3 1-.3 1.6 0 2.1 1.6 3.7 3.6 4z" />
        <path d="m8 10.8.9-.8-.9-.9 5.7-5.7 1.2-.4 1.1-2.2-.7-.7-2.3 1-.5 1.2-5.6 5.7-.9-.9-.8.9s.8.6-.1 1.5c-.5.5-1.3-.1-2.8 1.4-.5.5-2.1 2.1-2.1 2.1s-.6 1 .6 2.2 2.2.6 2.2.6 1.6-1.6 2.1-2.1c1.4-1.4.9-2.3 1.3-2.7.9-.9 1.6-.2 1.6-.2zm-3.1-.4.7.7-3.8 3.8-.7-.7z" />
      </g>
    </svg>
  </span>
);
export default ToolSvg;
