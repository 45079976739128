import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CategoryService from "services/Asset/Settings/CategoryService";

export const GetAllDmChungCate = createAsyncThunk(
  "category/GetAllDmChungCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CategoryService.getDmChung(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetAllAssetCate = createAsyncThunk(
  "category/GetAllAssetCate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CategoryService.getAssetCate(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const SearchDanhMucChungAsset = createAsyncThunk(
  "category/SearchDanhMucChungAsset",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CategoryService.searchDmChung(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertDanhMucChungAsset = createAsyncThunk(
  "category/UpsertDanhMucChungAsset",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await CategoryService.upSertDmChung(payload);
      if (onSuccess) {
        onSuccess(response.data);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  data: [],
  dvtData: [],
  nccData: [],
  llkData: [],
  assetData: [],
};

export const categoryAssetSlice = createSlice({
  name: "categoryAssetSlice",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllAssetCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllAssetCate.fulfilled, (state, action) => {
        state.loading = false;
        state.assetData = action.payload;
      })
      .addCase(GetAllAssetCate.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetAllDmChungCate.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllDmChungCate.fulfilled, (state, action) => {
        state.loading = false;
        state.nccData = action.payload.filter((x) => x.type === 1); //Nha cung cap
        state.llkData = action.payload.filter((x) => x.type === 2); //LoaiLinh Kien
        state.dvtData = action.payload.filter((x) => x.type === 3); //Don vi tinh
      })
      .addCase(GetAllDmChungCate.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(SearchDanhMucChungAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchDanhMucChungAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(SearchDanhMucChungAsset.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(UpsertDanhMucChungAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpsertDanhMucChungAsset.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpsertDanhMucChungAsset.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = categoryAssetSlice.actions;

export default categoryAssetSlice.reducer;
