import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LinhKienService from "services/Asset/List/LinhKienService";
import Utils from "utils";

export const SearchComponentAsset = createAsyncThunk(
  "linhKien/SearchComponentAsset",
  async (data, { rejectWithValue }) => {
    try {
      const response = await LinhKienService.search(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const UpsertComponentAsset = createAsyncThunk(
  "linhKien/UpsertComponentAsset",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await LinhKienService.upsert(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteComponentAsset = createAsyncThunk(
  "linhKien/DeleteComponentAsset",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await LinhKienService.delete(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const DeleteByIdsComponentAsset = createAsyncThunk(
  "linhKien/DeleteByIdsComponentAsset",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await LinhKienService.deleteByIds(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetComponentAssetDetail = createAsyncThunk(
  "linhKien/GetComponentAssetDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await LinhKienService.getById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const GetAllQuaTrinhSD = createAsyncThunk(
  "linhKien/GetAllQuaTrinhSD",
  async (id, { rejectWithValue }) => {
    try {
      const response = await LinhKienService.getAllQuaTrinhSD(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const UpsertComponentAssetOther = createAsyncThunk(
  "linhKien/UpsertComponentAssetOther",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, ...payload } = data;
      const response = await LinhKienService.upsertOther(payload);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  list: [],
  detail: null,
  quaTrinh: [],
};

const linhKienSlice = createSlice({
  name: "linhKien",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SearchComponentAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(SearchComponentAsset.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(SearchComponentAsset.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetComponentAssetDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetComponentAssetDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = {
          ...action.payload,
          ngayMua: Utils.convertMomentToDate(action.payload.ngayMua),
          hanBh: Utils.convertMomentToDate(action.payload.hanBh),
        };
      })
      .addCase(GetComponentAssetDetail.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpsertComponentAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpsertComponentAsset.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpsertComponentAsset.rejected, (state) => {
        state.loading = false;
      })
      .addCase(DeleteComponentAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteComponentAsset.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteComponentAsset.rejected, (state) => {
        state.loading = false;
      })
      .addCase(DeleteByIdsComponentAsset.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteByIdsComponentAsset.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(DeleteByIdsComponentAsset.rejected, (state) => {
        state.loading = false;
      })
      .addCase(GetAllQuaTrinhSD.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAllQuaTrinhSD.fulfilled, (state, action) => {
        state.loading = false;
        state.quaTrinh = action.payload.map((x) => ({
          ...x,
          ngayThucHien: Utils.convertMomentToDate(x.ngayThucHien),
        }));
      })
      .addCase(GetAllQuaTrinhSD.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const {} = linhKienSlice.actions;

export default linhKienSlice.reducer;
