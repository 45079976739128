import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import TaiSanService from "services/Asset/List/TaiSanService";

export const getThongTinThuHoiByTaiSanId = createAsyncThunk(
  "thuHoiTaiSan/getThongTinThuHoiByTaiSanId",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThongTinThuHoiByTaiSanId(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getThuHoi = createAsyncThunk(
  "thuHoiTaiSan/getThuHoi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThuHoi();
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const createThuHoiTaiSan = createAsyncThunk(
  "thuHoiTaiSan/createThuHoiTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.CreateThuHoi(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);
export const updateThuHoiTaiSan = createAsyncThunk(
  "thuHoiTaiSan/updateThuHoiTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.UpdateThuHoi(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const getThuHoiById = createAsyncThunk(
  "thuHoiTaiSan/getThuHoiById",
  async (data, { rejectWithValue }) => {
    try {
      const { id, onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetThuHoiById(id);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  thuHoiTaiSanList: [],
  thuHoiTaiSanDetail: null,
  thuHoiTaiSanChiTietList: [],
  thongTinTHTSList: [],
};

const thuHoiTaiSanSlice = createSlice({
  name: "thuHoiTaiSan",
  initialState,
  reducers: {
    updateThuHoiTaiSanChiTietList: (state, action) => {
      state.thuHoiTaiSanChiTietList = action.payload;
    },
    resetThuHoiTaiSanDetail: (state, action) => {
      state.thuHoiTaiSanDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThuHoi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThuHoi.fulfilled, (state, action) => {
        state.loading = false;
        state.thuHoiTaiSanList = action.payload;
      })
      .addCase(getThuHoi.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createThuHoiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createThuHoiTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createThuHoiTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateThuHoiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateThuHoiTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateThuHoiTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThuHoiById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThuHoiById.fulfilled, (state, action) => {
        state.loading = false;
        state.thuHoiTaiSanDetail = action.payload;
      })
      .addCase(getThuHoiById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getThongTinThuHoiByTaiSanId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThongTinThuHoiByTaiSanId.fulfilled, (state, action) => {
        state.loading = false;
        state.thongTinTHTSList = action.payload;
      })
      .addCase(getThongTinThuHoiByTaiSanId.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { updateThuHoiTaiSanChiTietList, resetThuHoiTaiSanDetail } =
  thuHoiTaiSanSlice.actions;

export default thuHoiTaiSanSlice.reducer;
