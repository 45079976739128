import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import SuaChuaService from "services/Asset/SuaChua/SuaChuaService";



export const createBaoHong= createAsyncThunk(
  "suaChua/createBaoHong",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SuaChuaService.CreateBaoHong(data);
      if (onSuccess) onSuccess(response);
      console.log(response,"res")
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createCanSua= createAsyncThunk(
  "suaChua/createCanSua",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await SuaChuaService.CreateCanSua(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const searchCanSua= createAsyncThunk(
    "suaChua/searchCanSua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.SeachCanSua(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );

  export const searchDangSua= createAsyncThunk(
    "suaChua/searchDangSua",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.SeachDangSua(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );


  export const seachTaiSan= createAsyncThunk(
    "suaChua/seachTaiSan",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await SuaChuaService.SeachTaiSan(data);
        if (onSuccess) onSuccess(response);
        return response.data;
      } catch (error) {
        console.error("API call failed:", error);
        return rejectWithValue(error.message || "Error");
      }
    }
  );
  

const initialState = {
  loading: false,
  danhsachtaisancansua:[],
  danhsachtaisandangsua:[]
  
};

const suaChuaSlice = createSlice({
  name: "suaChua",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBaoHong.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBaoHong.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createBaoHong.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchCanSua.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchCanSua.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachtaisancansua = action.payload
      })
      .addCase(searchCanSua.rejected, (state) => {
        state.loading = false;
      })

      .addCase(seachTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(seachTaiSan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(seachTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createCanSua.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCanSua.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createCanSua.rejected, (state) => {
        state.loading = false;
      })
      .addCase(searchDangSua.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchDangSua.fulfilled, (state, action) => {
        state.loading = false;
        state.danhsachtaisandangsua = action.payload
      })
      .addCase(searchDangSua.rejected, (state) => {
        state.loading = false;
      })
      
  },
});
export const { danhsachtaisancansua } = suaChuaSlice.actions;

export default suaChuaSlice.reducer;
