import axiosInstance from "auth/FetchInterceptor";
import {
  CREATE_NHOM_TAI_SAN_API,
  DELETE_NHOM_TAI_SAN_API,
  GET_NHOM_TAI_SAN_API,
  GET_NHOM_TAI_SAN_BY_ID_API,
  UPDATE_NHOM_TAI_SAN_API,
} from "constants/ApiConstant";

const NhomTaiSanService = {};

NhomTaiSanService.Get = function (data) {
  return axiosInstance.post(`${GET_NHOM_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

NhomTaiSanService.Create = function (data) {
  return axiosInstance.post(`${CREATE_NHOM_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

NhomTaiSanService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_NHOM_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
NhomTaiSanService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_NHOM_TAI_SAN_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

NhomTaiSanService.GetById = function (data) {
  return axiosInstance.get(`${GET_NHOM_TAI_SAN_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default NhomTaiSanService;
