import axiosInstance from "auth/FetchInterceptor";
import {
    SUA_CHUA_API
} from "constants/ApiConstant";

const SuaChuaService = {};


SuaChuaService.CreateBaoHong = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/bao-hong`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.CreateCanSua = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/create`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.SeachCanSua = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/search-can-sua`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.SeachDangSua = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/search-dang-sua`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

SuaChuaService.SeachTaiSan = function (data) {
  return axiosInstance.post(`${SUA_CHUA_API}/tai-san`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};



export default SuaChuaService;
