import axiosInstance from "auth/FetchInterceptor";
import {
  CAP_PHAT_TAI_SAN_API,
  CREATE_TAI_SAN_API,
  DELETE_TAI_SAN_API,
  GET_CAP_PHAT_BY_ID_API,
  GET_QUA_TRINH_SU_DUNG_API,
  GET_TAI_SAN_API,
  GET_TAI_SAN_BY_IDS_API,
  GET_THU_HOI_BY_ID_API,
  GET_TTTH_BY_TSID,
  TAI_SAN_API,
  THU_HOI_TAI_SAN_API,
  UPDATE_TAI_SAN_API,
} from "constants/ApiConstant";

const TaiSanService = {};

TaiSanService.Get = function (data) {
  return axiosInstance.post(`${GET_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.Create = function (data) {
  return axiosInstance.post(`${CREATE_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_TAI_SAN_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetById = function (data) {
  return axiosInstance.get(`${TAI_SAN_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetByIds = function (data) {
  return axiosInstance.post(`${GET_TAI_SAN_BY_IDS_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.CreateCapPhat = function (data) {
  return axiosInstance.post(`${CAP_PHAT_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.UpdateCapPhat = function (data) {
  return axiosInstance.put(`${CAP_PHAT_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetCapPhat = function () {
  return axiosInstance.get(`${CAP_PHAT_TAI_SAN_API}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetCapPhatById = function (data) {
  return axiosInstance.get(`${GET_CAP_PHAT_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetQuaTrinhSuDung = function (data) {
  return axiosInstance.get(`${GET_QUA_TRINH_SU_DUNG_API}?taiSanId=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

TaiSanService.GetThongTinThuHoiByTaiSanId = function (data) {
  return axiosInstance.post(`${GET_TTTH_BY_TSID}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.CreateThuHoi = function (data) {
  return axiosInstance.post(`${THU_HOI_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetThuHoi = function () {
  return axiosInstance.get(`${THU_HOI_TAI_SAN_API}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
TaiSanService.GetThuHoiById = function (data) {
  return axiosInstance.get(`${GET_THU_HOI_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
export default TaiSanService;
