import React from "react";
import ButtonHeaderRight from "./ButtonHeaderRight";

const MeditechTablePage = (props) => {
  const {
    tableTitle,
    btnHeaderRightSource,
    btnHeaderRightSourceHandle,
    onRenderSearchView,
    children,
    onRenderTitle,
  } = props;

  return (
    <div className="page">
      {/* Header View */}
      <div
        className="header d-flex justify-content-between"
        style={{ marginBottom: tableTitle ? 20 : 0 }}
      >
        {/* Title */}
        <div className="d-flex align-items-center">
          {tableTitle && <h1 className="general-title">{tableTitle}</h1>}
          {onRenderTitle && onRenderTitle()}
        </div>

        {/* Action Toolbar */}
        <div>
          {Array.isArray(btnHeaderRightSource) &&
            btnHeaderRightSource.length > 0 && (
              <ButtonHeaderRight
                keyValues={btnHeaderRightSource}
                onBtnHandle={btnHeaderRightSourceHandle}
              />
            )}
        </div>
      </div>
      <div className="mb-3">{onRenderSearchView && onRenderSearchView()}</div>
      {/* GridView */}
      {children}
    </div>
  );
};

export default MeditechTablePage;
