import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  memo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Modal, Row, Col, Card, Tabs, Form, Input, Button, Layout } from "antd";
import CommonAttachment from "../CommonAttachment";
import Expandable from "components/SignProcess/Expandable";
import SignProcess from "components/SignProcess/SignProcess";
import {
  createSignedDocument,
  signReject,
  signRemind,
} from "store/slices/signatureProcessSlice";
import { updateSignatureProcess } from "store/slices/internalSlice";
import { notification } from "antd";
import {
  LinkOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { DETAIL_PAGE_PATH } from "configs/AppConfig";
import { DOCUMENT_TYPE_COMP } from "constants/index";
import MediTimeline from "components/Custom/MediTimeline";
import { isMobile } from "react-device-detect";
// import styled from "styled-components";

const { confirm } = Modal;
const { Sider, Content } = Layout;
const SlipModalComponent = forwardRef(
  (
    {
      title,
      isVisibleModal,
      onCancelModal,
      onRenderInfo,
      widthInfo,
      onRenderTab,
      objectId = "",
      objectType = "",
      titleTLink = "",
      descriptionTLink = "",
      documentTypeId = null,
      activeKey,
      onSignReloadForm,
      selectedRow,
      historyList,
    },
    ref
  ) => {
    const [activeTabKey, setActiveTabKey] = useState("998");

    const items = useMemo(() => {
      const tabs = [];
      let key = 3;
      if (onRenderTab) {
        onRenderTab.forEach((item) => {
          tabs.push({
            key: ++key,
            label: item?.label,
            children: item?.children,
          });
        });
      }
      tabs.push(
        {
          key: "1",
          label: "File đính kèm",
          children: (
            <CommonAttachment
              objectId={objectId}
              objectType={objectType}
              selectedRow={selectedRow}
            />
          ),
        },
        {
          key: "2",
          label: "Lịch sử thay đổi",
          children: <MediTimeline data={historyList} />,
        }
      );
      return tabs;
    }, [historyList, objectId, objectType, onRenderTab, selectedRow]);

    const dispatch = useDispatch();
    const [signData, setSignData] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);
    const { branchId } = useSelector((state) => state.auth);

    const [searchParams] = useSearchParams();
    const onSetSignData = (data = {}) => {
      setSignData({ ...data });
    };

    const [visibleModal, setVisibleModal] = useState(true);
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      signedDocumentCreate: (
        id,
        code,
        description = "",
        managerById = null,
        employeeId = null,
        docId = null //DocumentId nếu có
      ) => {
        const payload = {
          documentId: id,
          documentCode: code,
          documentTypeId: docId || documentTypeId,
          branchId: branchId,
          description: description,
          managerById,
          employeeId,
          onSuccess: ({ data }) => {
            setForceUpdate((prev) => !prev);

            const payloadTotal = {
              documentType: docId != null ? docId : documentTypeId,
              objectId: id,
              totalSigns: data.totalSigns,
              totalSigned: data.totalSigned,
            };

            dispatch(updateSignatureProcess(payloadTotal));
          },
        };
        dispatch(createSignedDocument(payload));
      },
    }));

    useEffect(() => {
      if (items.length > 0) {
        setActiveTabKey(items[0].key);
      }
    }, [items]);

    const [collapsed, setCollapsed] = useState(false);
    const renderContent = useCallback(() => {
      return isMobile ? (
        <Row gutter={[16, 16]}>
          <Col
            xs={24} // Chiếm toàn bộ chiều rộng khi màn hình dưới md
            lg={8}
            md={12}
          >
            <Card className="custom-card">
              {onRenderInfo && onRenderInfo()}
            </Card>
          </Col>
          <Col
            xs={24} // Chiếm toàn bộ chiều rộng khi màn hình dưới md
            lg={16}
            md={12}
          >
            <Card className="custom-card">
              <Tabs
                activeKey={activeTabKey}
                items={items}
                onChange={setActiveTabKey}
              />
            </Card>
          </Col>
        </Row>
      ) : (
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed} width={460}>
            <Card
              className="custom-card"
              style={{ position: "relative", minWidth: "460px" }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed((prev) => !prev)}
                style={{
                  fontSize: "16px",
                  width: 35,
                  height: 35,
                  margin: 10,
                  marginBottom: 0,
                }}
              />
              <div
                style={{
                  display: collapsed && "none",
                  position: "absolute",
                  width: "100%",
                  transition: "transform 1s ease",
                }}
              >
                {onRenderInfo && onRenderInfo()}
              </div>
            </Card>
          </Sider>
          <Layout style={{ marginLeft: collapsed ? "-25px" : 15 }}>
            <Content>
              <Card className="custom-card">
                <Tabs
                  activeKey={activeTabKey}
                  items={items}
                  onChange={setActiveTabKey}
                />
              </Card>
            </Content>
          </Layout>
        </Layout>
      );
    }, [onRenderInfo, activeTabKey, items, collapsed]);

    const onFinishHandle = useCallback(
      (values) => {
        const payload = {
          documentId: objectId,
          documentTypeId: documentTypeId,
          reason: values.reason,

          onSuccess: ({ data }) => {
            console.log(visibleModal, "prev");
            setVisibleModal((prev) => !prev);
            console.log(visibleModal, "form");
            setForceUpdate((prev) => !prev);
            form.resetFields();

            const payloadTotal = {
              documentType: documentTypeId,
              objectId: objectId,
              totalSigns: data.totalSigns,
              totalSigned: data.totalSigned,
              updateType: -1, // loại Từ chối ký
              onSuccess: () => {
                if (onSignReloadForm) onSignReloadForm(data);
              },
            };

            dispatch(updateSignatureProcess(payloadTotal));
          },
        };

        dispatch(signReject(payload));
      },
      [dispatch, documentTypeId, form, objectId, onSignReloadForm, visibleModal]
    );
    const onRejectHandle = useCallback(
      (signName) => {
        confirm({
          icon: <></>,
          open: visibleModal,
          content: (
            <>
              <div className="text-center">
                <div
                  style={{ fontSize: 16, fontWeight: 500, marginBottom: 12 }}
                >
                  Bạn muốn từ chối ký?
                </div>
                <div style={{ marginBottom: 12 }}>
                  Vui lòng xác nhận bạn muốn từ chối ký vị trí{" "}
                  <span style={{ fontWeight: 500 }}>{signName}</span>. Sau khi
                  từ chối ký phiếu sẽ trở lại{" "}
                  <span style={{ fontWeight: 500 }}>trạng thái ban đầu</span>
                </div>
              </div>
              <Form form={form} layout="vertical" onFinish={onFinishHandle}>
                <Form.Item
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: "Lý do từ chối ký là bắt buộc",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Lý do từ chối ký" />
                </Form.Item>
              </Form>
            </>
          ),
          onOk() {
            return form
              .validateFields()
              .then((values) => {
                onFinishHandle(values);
              })
              .catch((errorInfo) => {
                return Promise.reject();
              });
          },
          // onCancel() {
          //     console.log('Cancel');
          // },
        });
      },
      [form, onFinishHandle, visibleModal]
    );

    const renderLink = () => {
      if (documentTypeId === null) {
        return;
      }

      const compDetail = DOCUMENT_TYPE_COMP.find(
        (item) => item.id === documentTypeId
      );

      if (!compDetail) {
        notification.error({
          message: "Không tìm thấy loại phiếu!",
          duration: 3,
        });
        return "";
      }

      const link = `[MEDIPHA ERP - ${titleTLink}] - ${descriptionTLink} 
${window.location.protocol}//${window.location.host}${DETAIL_PAGE_PATH}?page=${compDetail.comp}&${compDetail.comp}Id=${objectId}`;

      return link;
    };

    const handleCodeCopied = () => {
      notification.success({
        message: "Sao chép thành công!",
        duration: 1,
      });
    };

    const onRemindHandle = useCallback(() => {
      const payload = {
        documentId: objectId,
        documentTypeId: documentTypeId,
        // onSuccess: () => {},
      };

      dispatch(signRemind(payload));
    }, [dispatch, documentTypeId, objectId]);
    const onBackToParentPage = (pageName) => {
      let homePage = `${window.location.protocol}//${window.location.host}`;
      // todo
      if (
        pageName === "VanBanNoiBoDi" ||
        pageName === "QuanLyCongViec" ||
        pageName === "QuanLyCongVanBenNgoaiDen" ||
        pageName === "QuanLyCongVanBenNgoaiDi" ||
        pageName === "HoSoTaiLieu" ||
        pageName === "PhieuQuyChe" ||
        pageName === "PhieuVanBan"
      ) {
        homePage = `${homePage}/app/project`;
      }
      window.location.href = homePage;
    };
    const renderSignProcess = useCallback(() => {
      // return (<SignFooter documentTypeId={documentTypeId} documentId={objectId} />);
      return (
        <div style={{ overflow: "scroll" }}>
          {" "}
          <div className="sign-container">
            <Expandable
              signData={signData}
              onRejectHandle={onRejectHandle}
              onRemindHandle={onRemindHandle}
            >
              {documentTypeId && (
                <SignProcess
                  className="mb-2"
                  documentTypeId={documentTypeId}
                  documentId={objectId}
                  onSetSignData={onSetSignData}
                  forceUpdate={forceUpdate}
                  onSignReloadForm={onSignReloadForm}
                />
              )}
            </Expandable>
            {/* <Modal
        closable={false}
        width={383}
        open={visibleModal}
        onCancel={() => {
          setVisibleModal(!visibleModal);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <div className="text-center">
          <div style={{ fontSize: 16, fontWeight: 500, marginBottom: 12 }}>Bạn muốn từ chối ký?</div>
          <div style={{ marginBottom: 12 }}>Vui lòng xác nhận bạn muốn từ chối ký vị trí <span style={{ fontWeight: 500 }}>Thủ kho</span>. Sau khi từ chối ký bạn <span style={{ fontWeight: 500 }}>không thể ký lại</span> vị trí này nữa</div>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinishHandle}>
          <Form.Item
            name="reason"
            rules={[
              {
                required: true,
                message: "Lý do từ chối ký là bắt buộc",
              },
            ]}
          >
            <Input.TextArea
              placeholder="Lý do từ chối ký"
            />
          </Form.Item>
        </Form>
      </Modal> */}
          </div>
        </div>
      );
    }, [
      documentTypeId,
      forceUpdate,
      objectId,
      onRejectHandle,
      onRemindHandle,
      onSignReloadForm,
      signData,
    ]);

    return (
      <Modal
        title={
          <div className="d-flex align-items-center">
            {title || "Chi tiết"}{" "}
            {titleTLink && (
              <CopyToClipboard
                className="ml-auto mr-5"
                text={renderLink()}
                onCopy={handleCodeCopied}
              >
                <Button size="small" type="link" icon={<LinkOutlined />}>
                  Sao chép link
                </Button>
              </CopyToClipboard>
            )}
          </div>
        }
        open={isVisibleModal}
        onCancel={() => {
          setForceUpdate((prev) => !prev);

          const detailPage = searchParams.get("page");
          if (detailPage) {
            // Back to parent page
            onBackToParentPage(detailPage);
          } else {
            onCancelModal();
          }
        }}
        footer={renderSignProcess()}
        width={1660}
        height={"80vh"}
        className="sign-modal"
        // bodyStyle={{ overflowY: 'scroll' }}
        maskClosable={false}
      >
        {renderContent()}
      </Modal>
    );
  }
);

export default memo(SlipModalComponent);
