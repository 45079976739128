import axiosInstance from "auth/FetchInterceptor";
import {
  CREATE_LOAI_TAI_SAN_API,
  DELETE_LOAI_TAI_SAN_API,
  GET_LOAI_TAI_SAN_API,
  GET_LOAI_TAI_SAN_BY_ID_API,
  UPDATE_LOAI_TAI_SAN_API,
} from "constants/ApiConstant";

const LoaiTaiSanService = {};

LoaiTaiSanService.Get = function (data) {
  return axiosInstance.post(`${GET_LOAI_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LoaiTaiSanService.Create = function (data) {
  return axiosInstance.post(`${CREATE_LOAI_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LoaiTaiSanService.Update = function (data) {
  return axiosInstance.put(`${UPDATE_LOAI_TAI_SAN_API}`, data, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};
LoaiTaiSanService.Delete = function (data) {
  return axiosInstance.delete(`${DELETE_LOAI_TAI_SAN_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

LoaiTaiSanService.GetById = function (data) {
  return axiosInstance.get(`${GET_LOAI_TAI_SAN_BY_ID_API}?id=${data}`, {
    baseURL: process.env.REACT_APP_ASSET_ENDPOINT_URL,
  });
};

export default LoaiTaiSanService;
