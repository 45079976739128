import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import NhomTaiSanService from "services/Asset/Settings/NhomTaiSanService";

export const getNhomTaiSan = createAsyncThunk(
  "nhomTaiSan/getNhomTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NhomTaiSanService.Get(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateNhomTaiSan = createAsyncThunk(
  "nhomTaiSan/updateNhomTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await NhomTaiSanService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createNhomTaiSan = createAsyncThunk(
  "nhomTaiSan/createNhomTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await NhomTaiSanService.Create(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteNhomTaiSan = createAsyncThunk(
  "nhomTaiSan/deleteNhomTaiSan",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await NhomTaiSanService.Delete(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const getNhomTaiSanById = createAsyncThunk(
  "nhomTaiSan/getNhomTaiSanById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await NhomTaiSanService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  nhomTaiSanList: [],
  nhomTaiSanDetail: {
    data: null,
    loading: false,
  },
  nhomTaiSanDaPhatSinh: [],
};

const nhomTaiSanSlice = createSlice({
  name: "nhomTaiSan",
  initialState,
  reducers: {
    resetNhomTaiSanDetail: (state) => {
      state.nhomTaiSanDetail = {
        data: null,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNhomTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNhomTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.nhomTaiSanList = action.payload;
      })
      .addCase(getNhomTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateNhomTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNhomTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateNhomTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createNhomTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNhomTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createNhomTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteNhomTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNhomTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.nhomTaiSanDaPhatSinh = action.payload;
      })
      .addCase(deleteNhomTaiSan.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getNhomTaiSanById.pending, (state) => {
        state.nhomTaiSanDetail = {
          ...state.nhomTaiSanDetail,
          loading: true,
        };
      })
      .addCase(getNhomTaiSanById.fulfilled, (state, action) => {
        state.nhomTaiSanDetail = {
          data: action.payload,
          loading: false,
        };
      })
      .addCase(getNhomTaiSanById.rejected, (state) => {
        state.nhomTaiSanDetail = {
          ...state.nhomTaiSanDetail,
          loading: false,
        };
      });
  },
});
export const { resetNhomTaiSanDetail } = nhomTaiSanSlice.actions;

export default nhomTaiSanSlice.reducer;
