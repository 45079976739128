import React, { Fragment, useCallback } from "react";
import { Button, Dropdown, Menu, Space, Tooltip } from "antd";
import {
  EllipsisOutlined,
  LockOutlined,
  UnlockOutlined,
  CalculatorOutlined,
  FileProtectOutlined,
  DollarOutlined,
  ToolOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import { ExportSvgBtn, ImportSvgBtn, AddNewSvgBtn } from "components/Icon";

const svgButtonSource = {
  addBtn: AddNewSvgBtn,
  exportBtn: ExportSvgBtn,
  export1Btn: ExportSvgBtn,
  importBtn: ImportSvgBtn,
  import1Btn: ImportSvgBtn,
  lockBtn: LockOutlined,
  unLockBtn: UnlockOutlined,
  calculatorBtn: CalculatorOutlined,
  approveBtn: FileProtectOutlined,
  editBtn: ToolOutlined,
  dollarBtn: DollarOutlined,
  closeBtn : CloseCircleOutlined

};

const ButtonHeaderRight = (props) => {
  const { keyValues = [], onBtnHandle } = props;

  const onClickHandle = useCallback(
    (keyName, importFile, params) => () => {
      if (onBtnHandle) onBtnHandle(keyName, params);
      else if (importFile && importFile.inputRef)
        importFile.inputRef.current.click();
    },
    [onBtnHandle]
  );

  const showBtn = keyValues.filter((item) => !item.showMore);
  const showMoreBtn = keyValues.filter((item) => item.showMore);

  const items = [];

  const MenuItem = ({ keyName, title, importFile, params }) => {
    const SvgCompoment = svgButtonSource[keyName];
    return (
      <div id={`btn-${keyName}`} onClick={onClickHandle(keyName, importFile, params)}>
        {SvgCompoment && <SvgCompoment className="mr-2" />}
        {title}
      </div>
    );
  };

  showMoreBtn.forEach((item, index) => {
    items.push({
      key: `btn-${index}`,
      label: (
        <MenuItem
          keyName={item.keyName}
          title={item.title}
          importFile={item.importFile}
          params={item.params}
        />
      ),
    });
  });

  const menu = <Menu items={items} />;

  const renderInputFile = (inputRef, onChange, fileType) => {
    return (
      <input
        type="file"
        hidden
        accept={fileType}
        onChange={onChange}
        ref={inputRef}
        onClick={(event) => {
          const { target } = event;
          target.value = null;
        }}
      />
    );
  };
  return (
    <div className="d-flex">
      <div className="header-right-actions">
        {showBtn &&
          showBtn.map((item, index) => {
            const {
              keyName,
              title,
              tooltipText = "",
              // requiredPermits,
              disable = false,
              // disableTooltip,
              importFile,
              params,
            } = item;

            const SvgCompoment = svgButtonSource[keyName];

            const btnKey = `btn-${index}`;
            return (
              <Fragment key={btnKey}>
                {importFile &&
                  renderInputFile(
                    importFile.inputRef,
                    importFile.onChange,
                    importFile.fileType
                  )}
                {disable ? <Button
                  onClick={onClickHandle(keyName, importFile, params)}
                  className="d-inline-flex align-items-center"
                  type="link"
                  title={tooltipText}
                  icon={SvgCompoment && <SvgCompoment className="mr-2" />}
                  disabled={disable}
                >
                  {title}
                </Button> : <Tooltip title={tooltipText}>
                  <Button
                    onClick={onClickHandle(keyName, importFile, params)}
                    className="d-inline-flex align-items-center"
                    type="link"
                    icon={SvgCompoment && <SvgCompoment className="mr-2" />}
                    disabled={disable}
                  >
                    {title}
                  </Button>
                </Tooltip>}
              </Fragment>
            );
          })}
      </div>

      {showMoreBtn && showMoreBtn.length > 0 && (
        <Space direction="vertical">
          <Space wrap>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                className="ml-2"
                style={{ paddingLeft: "12px", paddingRight: "12px" }}
                onClick={(e) => e.preventDefault()}
              >
                <Space>
                  <EllipsisOutlined style={{ fontSize: "18px" }} />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        </Space>
      )}
    </div>
  );
};

export default ButtonHeaderRight;
