import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import TaiSanService from "services/Asset/List/TaiSanService";

export const getTaiSan = createAsyncThunk(
  "taiSan/getTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.Get(data);
      return response.data.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateTaiSan = createAsyncThunk(
  "taiSan/updateTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createTaiSan = createAsyncThunk(
  "taiSan/createTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.Create(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteTaiSan = createAsyncThunk(
  "taiSan/deleteTaiSan",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await TaiSanService.Delete(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const getTaiSanById = createAsyncThunk(
  "taiSan/getTaiSanById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await TaiSanService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const getTaiSanByIds = createAsyncThunk(
  "taiSan/getTaiSanByIds",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await TaiSanService.GetByIds(payload);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const getQuaTrinhSuDung = createAsyncThunk(
  "taiSan/getQuaTrinhSuDung",
  async (data, { rejectWithValue }) => {
    try {
      const response = await TaiSanService.GetQuaTrinhSuDung(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  taiSanList: [],
  taiSanByIds: [],
  taiSanDetail: {
    data: null,
    loading: false,
  },
  quaTrinhSuDungList: [],
};

const taiSanSlice = createSlice({
  name: "taiSan",
  initialState,
  reducers: {
    resetTaiSanDetail: (state) => {
      state.taiSanDetail = {
        data: null,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanList = action.payload;
      })
      .addCase(getTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTaiSan.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTaiSan.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTaiSanById.pending, (state) => {
        state.loading = true;
        state.taiSanDetail = {
          ...state.taiSanDetail,
          loading: true,
        };
      })
      .addCase(getTaiSanById.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanDetail = {
          data: action.payload,
          loading: false,
        };
      })
      .addCase(getTaiSanById.rejected, (state) => {
        state.loading = false;
        state.taiSanDetail = {
          ...state.taiSanDetail,
          loading: false,
        };
      })

      .addCase(getQuaTrinhSuDung.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuaTrinhSuDung.fulfilled, (state, action) => {
        state.loading = false;
        state.quaTrinhSuDungList = action.payload;
      })
      .addCase(getQuaTrinhSuDung.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTaiSanByIds.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaiSanByIds.fulfilled, (state, action) => {
        state.loading = false;
        state.taiSanByIds = action.payload;
      })
      .addCase(getTaiSanByIds.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { resetTaiSanDetail } = taiSanSlice.actions;

export default taiSanSlice.reducer;
