import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import LoaiTaiSanService from "services/Asset/Settings/LoaiTaiSanService";

export const getLoaiTaiSan = createAsyncThunk(
  "loaiTaiSan/getLoaiTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const response = await LoaiTaiSanService.Get(data);
      return response.data;
    } catch (err) {
      console.error("API call failed:", err);
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateLoaiTaiSan = createAsyncThunk(
  "loaiTaiSan/updateLoaiTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await LoaiTaiSanService.Update(data);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

export const createLoaiTaiSan = createAsyncThunk(
  "loaiTaiSan/createLoaiTaiSan",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await LoaiTaiSanService.Create(data);
      if (onSuccess) onSuccess(response.data);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.description || "Error");
    }
  }
);

export const deleteLoaiTaiSan = createAsyncThunk(
  "loaiTaiSan/deleteLoaiTaiSan",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await LoaiTaiSanService.Delete(id);
      if (onSuccess) onSuccess(response);
      return id;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);
export const getLoaiTaiSanById = createAsyncThunk(
  "loaiTaiSan/getLoaiTaiSanById",
  async (data, { rejectWithValue }) => {
    const { onSuccess, id } = data;
    try {
      const response = await LoaiTaiSanService.GetById(id);
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (error) {
      console.error("API call failed:", error);
      return rejectWithValue(error.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  loaiTaiSanList: [],
  loaiTaiSanDetail: {
    data: null,
    loading: false,
  },
};

const loaiTaiSanSlice = createSlice({
  name: "loaiTaiSan",
  initialState,
  reducers: {
    resetLoaiTaiSanDetail: (state) => {
      state.loaiTaiSanDetail = {
        data: null,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoaiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoaiTaiSan.fulfilled, (state, action) => {
        state.loading = false;
        state.loaiTaiSanList = action.payload;
      })
      .addCase(getLoaiTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateLoaiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLoaiTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateLoaiTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createLoaiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLoaiTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createLoaiTaiSan.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteLoaiTaiSan.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLoaiTaiSan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteLoaiTaiSan.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getLoaiTaiSanById.pending, (state) => {
        state.loaiTaiSanDetail = {
          ...state.loaiTaiSanDetail,
          loading: true,
        };
      })
      .addCase(getLoaiTaiSanById.fulfilled, (state, action) => {
        state.loaiTaiSanDetail = {
          data: action.payload,
          loading: false,
        };
      })
      .addCase(getLoaiTaiSanById.rejected, (state) => {
        state.loaiTaiSanDetail = {
          ...state.loaiTaiSanDetail,
          loading: false,
        };
      });
  },
});
export const { resetLoaiTaiSanDetail } = loaiTaiSanSlice.actions;

export default loaiTaiSanSlice.reducer;
